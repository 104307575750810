import { default as indexWfl2P4C3MsMeta } from "/var/www/nuxt-v3/prod/releases/3/pages/index.vue?macro=true";
import { default as simulation_45pinelgaSNsexdxYMeta } from "/var/www/nuxt-v3/prod/releases/3/pages/simulateurs-immobilier/simulation-pinel.vue?macro=true";
import { default as simulation_45ptzDtB5mOdv2QMeta } from "/var/www/nuxt-v3/prod/releases/3/pages/simulateurs-immobilier/simulation-ptz.vue?macro=true";
export default [
  {
    name: indexWfl2P4C3MsMeta?.name ?? "index",
    path: indexWfl2P4C3MsMeta?.path ?? "/",
    meta: indexWfl2P4C3MsMeta || {},
    alias: indexWfl2P4C3MsMeta?.alias || [],
    redirect: indexWfl2P4C3MsMeta?.redirect,
    component: () => import("/var/www/nuxt-v3/prod/releases/3/pages/index.vue").then(m => m.default || m)
  },
  {
    name: simulation_45pinelgaSNsexdxYMeta?.name ?? "simulateurs-immobilier-simulation-pinel",
    path: simulation_45pinelgaSNsexdxYMeta?.path ?? "/simulateurs-immobilier/simulation-pinel",
    meta: simulation_45pinelgaSNsexdxYMeta || {},
    alias: simulation_45pinelgaSNsexdxYMeta?.alias || [],
    redirect: simulation_45pinelgaSNsexdxYMeta?.redirect,
    component: () => import("/var/www/nuxt-v3/prod/releases/3/pages/simulateurs-immobilier/simulation-pinel.vue").then(m => m.default || m)
  },
  {
    name: simulation_45ptzDtB5mOdv2QMeta?.name ?? "simulateurs-immobilier-simulation-ptz",
    path: simulation_45ptzDtB5mOdv2QMeta?.path ?? "/simulateurs-immobilier/simulation-ptz",
    meta: simulation_45ptzDtB5mOdv2QMeta || {},
    alias: simulation_45ptzDtB5mOdv2QMeta?.alias || [],
    redirect: simulation_45ptzDtB5mOdv2QMeta?.redirect,
    component: () => import("/var/www/nuxt-v3/prod/releases/3/pages/simulateurs-immobilier/simulation-ptz.vue").then(m => m.default || m)
  }
]